// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-cicerone-js": () => import("./../../../src/pages/cicerone.js" /* webpackChunkName: "component---src-pages-cicerone-js" */),
  "component---src-pages-covid-js": () => import("./../../../src/pages/covid.js" /* webpackChunkName: "component---src-pages-covid-js" */),
  "component---src-pages-incendios-js": () => import("./../../../src/pages/incendios.js" /* webpackChunkName: "component---src-pages-incendios-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-microcerveceria-js": () => import("./../../../src/pages/microcerveceria.js" /* webpackChunkName: "component---src-pages-microcerveceria-js" */),
  "component---src-pages-nosotros-js": () => import("./../../../src/pages/nosotros.js" /* webpackChunkName: "component---src-pages-nosotros-js" */),
  "component---src-pages-nuestrascervezas-js": () => import("./../../../src/pages/nuestrascervezas.js" /* webpackChunkName: "component---src-pages-nuestrascervezas-js" */),
  "component---src-pages-nuestroproposito-js": () => import("./../../../src/pages/nuestroproposito.js" /* webpackChunkName: "component---src-pages-nuestroproposito-js" */),
  "component---src-pages-recetasespeciales-js": () => import("./../../../src/pages/recetasespeciales.js" /* webpackChunkName: "component---src-pages-recetasespeciales-js" */),
  "component---src-pages-refugios-js": () => import("./../../../src/pages/refugios.js" /* webpackChunkName: "component---src-pages-refugios-js" */),
  "component---src-pages-yomequedoencasa-js": () => import("./../../../src/pages/yomequedoencasa.js" /* webpackChunkName: "component---src-pages-yomequedoencasa-js" */),
  "component---src-templates-detail-gin-js": () => import("./../../../src/templates/DetailGin.js" /* webpackChunkName: "component---src-templates-detail-gin-js" */),
  "component---src-templates-detail-hl-js": () => import("./../../../src/templates/DetailHL.js" /* webpackChunkName: "component---src-templates-detail-hl-js" */),
  "component---src-templates-detail-isidra-js": () => import("./../../../src/templates/DetailIsidra.js" /* webpackChunkName: "component---src-templates-detail-isidra-js" */),
  "component---src-templates-detail-js": () => import("./../../../src/templates/Detail.js" /* webpackChunkName: "component---src-templates-detail-js" */)
}

